import { Mapper } from "src/app/core/base/mapper";
import { TimeWindowModel } from "src/app/core/models/time-window.model";
import { UiTimeWindowModel } from "../models/ui-time-window.model";
import moment from 'moment';

export class UiTimeWindowMapper extends Mapper<UiTimeWindowModel, TimeWindowModel>{
    mapFrom(param: UiTimeWindowModel): TimeWindowModel{
        let tw = {...param as any};
        if (param.startDate){
            param.startDate.hour(Number( param.startTime!.split(':')[0] ) );
            param.startDate.minute(Number( param.startTime!.split(':')[1] ) );
            param.startDate.seconds(0);
            tw.startTs = moment(param.startDate);
        }
        if (param.endDate){
            param.endDate.hour( Number( param.endTime!.split(':')[0] ) );
            param.endDate.minute( Number( param.endTime!.split(':')[1] ) );
            param.endDate.seconds(0);
            tw.endTs = moment(param.endDate);
        }
        delete tw.startDate
        delete tw.startTime;
        delete tw.endDate;
        delete tw.endTime;
        return tw as TimeWindowModel;
    }
    
    mapTo(param: TimeWindowModel): UiTimeWindowModel{
        let tw = {...param as any};
        if (param.startTs){
            tw.startDate = moment(param.startTs);
            tw.startTime = moment(param.startTs).format('HH:mm');
        }
        if (param.endTs){
            tw.endDate = moment(param.endTs);
            tw.endTime = moment(param.endTs).format('HH:mm');
        }
        return tw as UiTimeWindowModel;
    }
}
