import { Component, Input, output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss'
})
export class TimePickerComponent {
  @Input() time: string | undefined;
  options: string[] = []
  myControl = new FormControl('');
  filteredOptions!: Observable<string[]>;
  timeChange = output<string>();

  ngOnInit() {
    this.generateTimeOptions();
    if (this.time){
      this.myControl.setValue(this.time);
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.myControl.valueChanges.subscribe(value => {
        if (value != null){
          this.timeChange.emit(value)
        }
    });
  }

  

  private generateTimeOptions() {
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        this.options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
