<div class="layout-container">
  <div class="layout">
    <form class="profile-form mat-typography">
      <input hidden type="file" #uploader (change)="imageChangeEvent($event)" />
      <h2 translate>profilePageTitle</h2>
      <div class="avatar">
        <img 
          *ngIf="this.visibleUser.image; else placeholder" 
          [src]="this.visibleUser.image.image"
        >
        <ng-template #placeholder>
          <img src="assets/imgs/user-icon.png">
        </ng-template>
        <button mat-mini-fab color="primary" (click)="uploader.click()">
          <mat-icon>camera_alt</mat-icon>
        </button>
      </div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label translate>firstName</mat-label>
          <input name="firstName" matInput type="text" 
            [(ngModel)]="this.visibleUser.firstName" (ngModelChange)="userDataChanged()">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label translate>lastName</mat-label>
          <input name="lastName" matInput type="text" [(ngModel)]="this.visibleUser.lastName"
            (ngModelChange)="userDataChanged()">
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label translate>email</mat-label>
        <input name="email" matInput type="text" id="emailField"
          [(ngModel)]="this.visibleUser.email" (ngModelChange)="userDataChanged()">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label translate>company</mat-label>
        <input name="company" matInput type="text" id="companyField"
          [(ngModel)]="this.visibleUser.company" (ngModelChange)="userDataChanged()">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label translate>profession</mat-label>
        <input name="profession" matInput type="text" id="professionField"
          [(ngModel)]="this.visibleUser.profession" (ngModelChange)="userDataChanged()">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label translate>comment</mat-label>
        <textarea name="comment" matInput type="text" id="commentField"
          [(ngModel)]="this.visibleUser.comment" (ngModelChange)="userDataChanged()"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label translate>language</mat-label>
        <mat-select [(value)]="selectedLanguage" (selectionChange)="userDataChanged()">
          <mat-option value="en">
            <span translate>english</span>
          </mat-option>
          <mat-option value="de">
            <span translate>german</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label translate>Date Format</mat-label>
        <mat-select [(value)]="selectedLocale" (selectionChange)="userDataChanged()">
          @for (locale of this.availableLocales; track locale) {
            <mat-option [value]="locale">{{locale}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div class="form-row action-row">


        <button mat-stroked-button 
          color="primary" 
          [disabled]="!this.dataHasChanged"
          (click)="cancelChanges()">
          <mat-icon>undo</mat-icon>
          <span translate>cancel</span>
        </button>
        <button mat-flat-button 
          color="accent" 
          [disabled]="!this.dataHasChanged"
          (click)="saveUserData()">
          <mat-icon>save</mat-icon>
          <span translate>save</span>
        </button>
        
      </div>

      <mat-divider></mat-divider>

      <div class="form-row">
        <a mat-stroked-button 
          color="primary" 
          href="/reset"
        >
          <span translate>changePassword</span>
          &nbsp;
          <mat-icon>link</mat-icon>
        </a>
  
        <button mat-stroked-button
          color="warn" 
          (click)="confirmAccountDelete()"
        >
          <span translate>deleteProfile</span>
          &nbsp;
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </form>
  </div>
</div>