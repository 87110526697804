<div class="layout">
  <form [formGroup]="keyFormGroup" class="keyForm">
    <ng-container formGroupName="generic">
      <mat-form-field appearance="outline" class="field-100">
        <mat-label>{{'Type' | translate}}</mat-label>
        <span matPrefix style="margin-right: 8px;"
          [ngSwitch]="keyTypeControl.value">
          <mat-icon *ngSwitchCase=this.ComydoKeyType.Plate> directions_car
          </mat-icon>
          <mat-icon *ngSwitchCase=this.ComydoKeyType.Badge> badge
          </mat-icon>
          <mat-icon *ngSwitchCase=this.ComydoKeyType.PIN> pin
          </mat-icon>
          <mat-icon *ngSwitchCase=this.ComydoKeyType.Custom> qr_code_2
          </mat-icon>
          <mat-icon *ngSwitchCase=this.ComydoKeyType.Tracking> local_shipping
          </mat-icon>
          <mat-icon *ngSwitchDefault> error
          </mat-icon>
        </span>
        <mat-select formControlName="type">
          <mat-option id="key" value={{this.ComydoKeyType.Custom}}
            (click)="onCustomKeySelected()">
            {{ComydoKeyNames.get(this.ComydoKeyType.Custom)! | translate}}
          </mat-option>
          <mat-option id="plate" value={{this.ComydoKeyType.Plate}}
            (click)="onLicensePlateKeySelected()">
            {{ComydoKeyNames.get(this.ComydoKeyType.Plate)! | translate}}
          </mat-option>
          <!-- <mat-option id="card" value={{this.ComydoKeyType.Badge}}>
                Badge
              </mat-option> -->
          <mat-option id="pin" value={{this.ComydoKeyType.PIN}}
            (click)="onPinKeySelected()">
            {{ComydoKeyNames.get(this.ComydoKeyType.PIN)! | translate}}
          </mat-option>
          <mat-option id="tracking" value={{this.ComydoKeyType.Tracking}}
            (click)="onTrackingKeySelected()">
            {{ComydoKeyNames.get(this.ComydoKeyType.Tracking)! | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="outline" class="field-100">
      <mat-label>{{"Name" | translate}}</mat-label>
      <mat-hint>{{"To manage your keys" | translate}}</mat-hint>
      <input matInput 
        id="key-name"
        placeholder="e.g. Entrance"
        formControlName="name"
      >
      
    </mat-form-field>

    <ng-container [ngSwitch]="keyTypeControl.value">
      <!-- The different key type cases -->

      <ng-container *ngSwitchCase=this.ComydoKeyType.Custom>
        <mat-form-field appearance="outline" class="field-100">
          <mat-label>{{"Key Code" | translate}}</mat-label>
          <mat-hint>
            {{ 'The QR code contains this. Will be randomly assigned, if left empty.' | translate }}
          </mat-hint>
          <input matInput 
            id="key-teeth" 
            placeholder="524ff85bc143b4c84"
            formControlName="keyTeeth"
            [matAutocomplete]="keyTeethAutoComplete"
          >

          <mat-autocomplete autoActiveFirstOption 
            #keyTeethAutoComplete="matAutocomplete"
          >
            <mat-option
              *ngFor="
                let key of (this.unassignedKeys)"
                [value]="key.keyTeeth"
                (click)="onUnassignedKeySelected(key)"
            >
              {{key.keyTeeth}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase=this.ComydoKeyType.Plate
        formGroupName="generic">
        <mat-form-field appearance="outline" class="field-100">
          <mat-label>{{"License Plate" | translate}}</mat-label>
          <input matInput id="licensePlate" placeholder="COMYD0"
            oninput="this.value = this.value.toUpperCase()"
            formControlName="licensePlate">
          <mat-hint>
            {{"All characters on the license plate of the given vehicle" | translate }}
          </mat-hint>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase=this.ComydoKeyType.PIN>
        <mat-form-field appearance="outline" class="field-100">
          <mat-label>PIN</mat-label>
          <input matInput id="keyTeeth" type="number" placeholder="1234"
            formControlName="keyTeeth" [value]="this.keyFormGroup.value.keyTeeth">
          <mat-hint>
            {{"The PIN digits" | translate }}
          </mat-hint>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase=this.ComydoKeyType.Tracking>
        <mat-form-field appearance="outline" class="field-100">
          <mat-label>{{"Tracking Key" | translate}}</mat-label>
          <input matInput id="keyTeeth" placeholder="524ff85bc143b4c84"
            formControlName="keyTeeth" [value]="this.keyFormGroup.value.keyTeeth">
          <mat-hint>
            {{"Enter the package tracking number." | translate}}
            {{"This key will be single-use and automatically expire after 30 days." | translate}}
          </mat-hint>
        </mat-form-field>
      </ng-container>

    </ng-container>

    <mat-form-field appearance="outline" class="field-100">
      <mat-label>{{"Key Holder" | translate}}</mat-label>
      <mat-select matInput id="key-holder" formControlName="keyHolder">
        <mat-option id="kh-option-null" [value]="undefined">
          ---
        </mat-option>
        <ng-container *ngFor="let kh of keyHolders; let i = index;">
          <mat-option id="kh-option-{{i}}" [value]="kh.id">
            {{kh.firstName}}{{kh.lastName}}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-hint>
        {{"Who will recieve the key?" | translate}}
        <a [routerLink]=[this.manageKeyHolderLink]>
          {{"Manage Key Holders" | translate}}
        </a>
      </mat-hint>
    </mat-form-field>

  </form>

  <!-- <h4>{{'Sharing' | translate}}</h4>
  <mat-divider></mat-divider> -->

  <mat-expansion-panel class="mat-elevation-z0">

    <mat-expansion-panel-header>
      {{'Advanced Validity Settings' | translate}}
    </mat-expansion-panel-header>

    <div [hidden]="this.keyRightsArray.length > 0">
      <button mat-icon-button
        class="action-icon"
        (click)="addKeyRight()"
      >
                  <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>

    <form [formGroup]="keyRightFormGroup">
      <div class="keyright-container" formArrayName="keyRights">

        <ng-container *ngFor="let keyRight of keyRightsArray.controls; 
                    let keyRightIndex = index;">
          <mat-expansion-panel 
            class="mat-elevation-z0"
            [formGroupName]="keyRightIndex"
          >

            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>key</mat-icon>
                &nbsp; {{'Key Right' | translate}}{{' ' + (keyRightIndex+1)}}
                <button mat-icon-button class="action-icon"
                  (click)="$event.stopPropagation(); 
                          addKeyRight()"
                >
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
                <button mat-icon-button color="warn"
                  (click)="$event.stopPropagation(); 
                          confirmKeyRightDelete(keyRight)"
                >
                  <mat-icon>delete_outline</mat-icon>
              </button>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="keyright-container">
              <div class="keyright-fields">
                <mat-form-field appearance="outline" class="field-100">
                  <mat-label>{{"Group" | translate}}</mat-label>
                  <mat-select matInput #groupSelect id="group-{{keyRightIndex}}" 
                    formControlName="groupId">
                    <ng-container *ngFor="let g of breadCrumpGroups; let j = index;">
                      <mat-option id="group-option-{{keyRightIndex}}-{{j}}"
                        [value]="g.id"
                      >
                        <!-- {{g.level}} -->
                        {{g.name}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-hint>
                    {{"theKeyOpensAllGroupsUnderneathThisGroup" |
                    translate}}
                  </mat-hint>
                </mat-form-field>

                  <div class="time-fields">
                    
                    <mat-form-field appearance="outline">
                      <mat-label>{{"Valid from" | translate}}</mat-label>
                      <input id="start-ts-{{keyRightIndex}}" matInput
                        [matDatepicker]="picker1"
                        formControlName="startDate">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <app-time-picker 
                      [(time)]="this.keyRightFormGroup.value.keyRights[keyRightIndex].startTime">
                    </app-time-picker>
                    
                    <p>
                      <b>―</b>
                    </p>

                    <mat-form-field appearance="outline">
                      <mat-label>{{"Valid until (otional)" | translate}}</mat-label>
                      <input id="end-ts-{{keyRightIndex}}" matInput
                        [matDatepicker]="picker2"
                        formControlName="expirationDate">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                    <app-time-picker 
                      [(time)]="this.keyRightFormGroup.value.keyRights[keyRightIndex].expirationTime">
                    </app-time-picker>

                    <p>
                    <mat-checkbox formControlName="isSingleUse"
                      [checked]="keyRight.get('isSingleUse')?.value === true">
                      {{"Single Use" | translate}}
                    </mat-checkbox>
                    </p>
                    
                  </div>

              </div>
              <div class="keyright-timewindows" formArrayName="timeWindows">
                <div>
                  <b>
                    <mat-icon>lock_clock</mat-icon>
                    {{'Additional Optional Time Restriction(s)' | translate}}
                  </b>
                </div>
                
                <ng-container 
                  [formGroupName]="j" 
                  *ngFor="let timeWindow of getTimeWindwoControls(keyRight); 
                          let j=index;"
                >
                  <div class="time-fields">
                    <button mat-stroked-button class="date-button"
                      disabled="true">
                      {{timeWindow?.value.startDate?.format('ddd')}}
                    </button>
                    <mat-form-field
                      appearance="outline"
                    >
                      <mat-label>
                        {{"Time Restriction Start" | translate}}
                      </mat-label>
                      <input matInput id="tw-start-ts-{{keyRightIndex}}-{{j}}"
                        [matDatepicker]="picker3"
                        [min]="this.keyRightFormGroup.value.keyRights[keyRightIndex].startDate"
                        [max]="this.keyRightFormGroup.value.keyRights[keyRightIndex]?.expirationDate"
                        formControlName="startDate"
                        #startTs>
                      <mat-datepicker-toggle matSuffix [for]="picker3">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>

                    <app-time-picker 
                      [(time)]="timeWindow.value.startTime">
                    </app-time-picker>

                    <p><b>―</b></p>
                    <button mat-stroked-button class="date-button"
                      disabled="true">
                      {{(timeWindow?.value.endDate?.format('ddd'))}}
                    </button>
                    <mat-form-field appearance="outline">
                      <mat-label>
                        {{"Time Restriction End" | translate}}
                      </mat-label>
                      <input matInput id="tw-end-ts-{{keyRightIndex}}-{{j}}"
                        [matDatepicker]="picker4"
                        [min]="this.keyRightFormGroup.value.keyRights[keyRightIndex].startDate"
                        [max]="this.keyRightFormGroup.value.keyRights[keyRightIndex]?.expirationDate"
                        formControlName="endDate"
                        #endTs>
                      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </mat-form-field>

                    <app-time-picker 
                      [(time)]="timeWindow.value.endTime">
                    </app-time-picker>

                    <mat-form-field
                      appearance="outline"
                    >
                      <mat-label>{{"Repetition" | translate}}</mat-label>
                      <mat-select id="reoccurence-{{keyRightIndex}}-{{j}}"
                        matInput formControlName="reoccurrence">
                        <mat-option id="reoccurence-once-{{keyRightIndex}}-{{j}}"
                          value="ONCE">{{'Does not repeat' | translate}}</mat-option>
                        <mat-option id="reoccurence-daily-{{keyRightIndex}}-{{j}}"
                          value="DAILY">{{'Daily' | translate}}</mat-option>
                        <mat-option
                          id="reoccurence-weekly-{{keyRightIndex}}-{{j}}"
                          value="WEEKLY">{{'Weekly' | translate}}</mat-option>
                        <mat-option
                          id="reoccurence-monthly-{{keyRightIndex}}-{{j}}"
                          value="MONTHLY">{{'Monthly' | translate}}</mat-option>
                        <mat-option
                          id="reoccurence-yearly-{{keyRightIndex}}-{{j}}"
                          value="YEARLY">{{'Yearly'}}</mat-option>
                      </mat-select>
                      <mat-hint>
                        {{'In what interval shall the time restriction be repeated?' | translate}}
                      </mat-hint>
                    </mat-form-field>

                    <button mat-icon-button
                      color="warn"
                      (click)="confirmTimeWindowDelete(keyRightIndex, j)">
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                  </ng-container>
                
              </div>
            </div>

            <mat-action-row>
              <button mat-button color="primary"
                (click)="addTimeWindow(keyRightIndex)"
              >
                  {{'Add Time Restriction' | translate}}
              </button>
            </mat-action-row>
          </mat-expansion-panel>
        </ng-container>
      </div>
    </form>
  </mat-expansion-panel>

  <div class="form-row">

    <button mat-flat-button mat-dialog-close
      class="comydoButton" 
      color="primary"
      [disabled]="!keyFormGroup.valid"
      (click)="onSubmit()"
    >
        {{'Save' | translate}}
    </button>

  </div>
</div>
