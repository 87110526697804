<form>
    <mat-form-field class="time-picker">
      <mat-label>Time</mat-label>
      <input type="text"
             placeholder="00:00"
             aria-label="Time"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">{{option}}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>
  