<div class="home-container" (mousemove)="onMouseMove($event)" (mouseup)="finishResize($event)">

  <div class="side-menu" [style.width]="sideBarStyleWidth">
    <app-group-tree></app-group-tree>
  </div>

  <div class="handle-bar" (mousedown)="startResize($event)">
    <div></div>
  </div>

  <div class="home-detail">
    <ng-container [ngSwitch]="this.currentPage">

      <ng-container *ngSwitchCase="this.SubPageType.Group">
        <ng-container *ngIf="selectedGroup">
          <h3 class="tab-title">Group: {{selectedGroup.name}}</h3>

          <mat-divider></mat-divider>

          <nav mat-tab-nav-bar class="navbar" [tabPanel]="tabPanel">
            <a mat-tab-link 
              routerLink="groupdetails"
              [active]="currentTab === TabType.GroupDetails"
              (click)="currentTab = TabType.GroupDetails"
            >
              {{"Group Details" | translate}}
            </a>

            <a mat-tab-link 
              routerLink="keys" 
              [active]="currentTab === TabType.Keys"
              (click)="currentTab = TabType.Keys"
            >
              {{"Keys" | translate}} 
            </a>

            <a mat-tab-link 
                routerLink="roles"
                [active]="currentTab === TabType.Roles"
                (click)="currentTab = TabType.Roles">
                {{"User Roles" | translate}} 
            </a>
          </nav>
          <mat-tab-nav-panel #tabPanel>
            <div class="home-detail-content-container">
              <div class="home-detail-content">
                <router-outlet></router-outlet>
              </div>
            </div>
          </mat-tab-nav-panel>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="this.SubPageType.Customer">
        <div class="home-detail-content-container">
          <div class="home-detail-content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="home-detail-content-container">
          <div class="home-detail-content">
            <div class="detail-placeholder">
              {{"Please select a group or customer" | translate}}
            </div>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </div>
</div>