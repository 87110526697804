<div class="layout">
    <form [formGroup]="formGroupDetails" (ngSubmit)="updateGroup()">
        <mat-form-field appearance="outline">
            <mat-label for="name">
                {{"Group Name" | translate}}
            </mat-label>
            <input matInput id="name" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label for="description"> {{"Group Description" |
                translate}} </mat-label>
            <input matInput id="description" formControlName="description">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label for="parentId">
                {{"Parent Group" | translate}}
            </mat-label>
            <mat-select id="parentId" formControlName="parentId">
                <!-- <mat-option>{{this.visibleGroup.parentId}}</mat-option> -->
                <ng-container *ngFor="let group of this.breadCrumpGroups">
                    <mat-option [value]="group.id">
                        {{group.name}}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-expansion-panel class="mat-elevation-z0 mat-form-field-appearance-outline" [expanded]="panelOpenState">
            <mat-expansion-panel-header >
                <mat-panel-title>
                    {{ "Address (optional)" | translate}}
                </mat-panel-title>
                <!-- <mat-slide-toggle [checked]="panelOpenState"></mat-slide-toggle> -->
            </mat-expansion-panel-header>
            <div formGroupName="address">
                <mat-list-item>
                    <mat-form-field appearance="outline">
                        <mat-label for="street"> {{"Street" | translate}}
                        </mat-label>
                        <input matInput id="street" type="text" formControlName="street">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label for="number"> {{"Number" | translate}}
                        </mat-label>
                        <input matInput id="number" type="text" formControlName="number">
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field appearance="outline">
                        <mat-label for="city"> {{"City" | translate}}
                        </mat-label>
                        <input matInput id="city" type="text" formControlName="city">
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field appearance="outline">
                        <mat-label for="zip"> {{"Zip Code" | translate}}
                        </mat-label>
                        <input matInput id="zip" type="text" formControlName="zip">
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field appearance="outline">
                        <mat-label for="country"> {{"Country" | translate}}
                        </mat-label>
                        <input matInput id="country" type="text" formControlName="country">
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field appearance="outline">
                        <mat-label for="extra"> {{"Extra" | translate}}
                        </mat-label>
                        <input matInput id="extra" type="text" formControlName="extra">
                    </mat-form-field>
                </mat-list-item>
            </div>
        </mat-expansion-panel>
        <div class="filler"></div>

        <div class="form-row">
            
            <button mat-flat-button 
                color="primary" 
                *ngIf="this.visibleGroup.permissions?.openLock" 
                (click)="openCurrentLock()" 
                [matTooltip]="'Open Lock' | translate"
            >
                {{"Open Lock" | translate}}
                &nbsp;
                <mat-icon>lock_open</mat-icon>
            </button>
            <button mat-flat-button mat-dialog-close 
                type="submit" 
                color="primary" 
                [disabled]="!this.formGroupDetails.dirty || !this.formGroupDetails.valid"
            >
                {{"Update Group" | translate}}
            </button>
        </div>
    </form>
</div>