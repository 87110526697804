import { Mapper } from "src/app/core/base/mapper";
import { KeyRightModel } from "src/app/core/models/key-right.model";
import { UiKeyRightModel } from "../models/ui-key-right.model";
import { UiTimeWindowMapper } from "./ui-time-window.mapper";
import moment from 'moment';

export class UiKeyRightMapper extends Mapper<UiKeyRightModel, KeyRightModel>{
    twMapper = new UiTimeWindowMapper;

    mapFrom(param: UiKeyRightModel): KeyRightModel {
        let kr = {... param as any};
        kr.expirationTs = null;
        if (param.startDate){
            param.startDate.hour(Number( param.startTime!.split(':')[0] ) );
            param.startDate.minute(Number( param.startTime!.split(':')[1] ) );
            param.startDate.seconds(0);
            kr.startTs = moment(param.startDate);
        }
        if (param.expirationDate){
            if (param.expirationTime == null)
            {
                param.expirationTime = "00:00";
            }
            param.expirationDate.hour( Number( param.expirationTime!.split(':')[0] ) );
            param.expirationDate.minute( Number( param.expirationTime!.split(':')[1] ) );
            param.expirationDate.seconds(0);
            kr.expirationTs = moment(param.expirationDate);
        }
        delete kr.startDate
        delete kr.startTime;
        delete kr.expirationDate;
        delete kr.expirationTime;
        kr.timeWindows = [];
        for (let tw of param.timeWindows){
            kr.timeWindows.push(this.twMapper.mapFrom(tw))
        }
        return kr as KeyRightModel;
    }
    mapTo(param: KeyRightModel): UiKeyRightModel {
        let kr = {... param as any};
        if (param.startTs){
            kr.startDate = moment(param.startTs);
            kr.startTime = moment(param.startTs).format('HH:mm');
        }
        if (param.expirationTs){
            kr.expirationDate = moment(param.expirationTs);
            kr.expirationTime = moment(param.expirationTs).format('HH:mm');
        }
        kr.timeWindows = [];
        for (let tw of param.timeWindows){
            kr.timeWindows.push(this.twMapper.mapTo(tw))
        }
        return kr as UiKeyRightModel;
    }    
}
