import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, signal, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { CustomerModel } from 'src/app/core/models/customer.model';
import { GroupModel } from 'src/app/core/models/group.model';
import { KeyHolderModel } from 'src/app/core/models/key-holder.model';
import { KeyModel, ReoccurEnum } from 'src/app/core/models/key.model';
import { GroupTreeService } from 'src/app/core/services/group-tree.service';
import { ComydoKeyNames, ComydoKeyType } from 'src/app/core/types/KeyType';
import { GetCustomerByGroupIdUsecase } from 'src/app/core/useCases/customer.usecases';
import { GetAllUnassignedKeysUsecase } from 'src/app/core/useCases/key.usecases';
import { GetAllKeyHoldersUsecase } from 'src/app/core/useCases/keyholder.usecases';
import { TRACKING_KEY_VALIDITY } from 'src/global';
import { ConfirmDeleteDialogComponent } from '../../../../dialogs/confirm-delete.dialog';
import { UiGroupMapper } from '../../../../mappers/ui-group.mapper';
import { UiKeyHolderMapper } from '../../../../mappers/ui-key-holder.mapper';
import { UiKeyRightMapper } from '../../../../mappers/ui-key-right.mapper';
import { UiGroupModel } from '../../../../models/ui-group.model';
import { UiKeyHolderModel } from '../../../../models/ui-key-holder.model';
import { UiKeyRightModel } from '../../../../models/ui-key-right.model';
import { UiKeyModel } from '../../../../models/ui-key.model';
import { UiTimeWindowModel } from '../../../../models/ui-time-window.model';
import { UiKeyMapper } from 'src/app/presentation/mappers/ui-key.mapper';
import moment from 'moment';


@Component({
  selector: 'app-key-editor',
  templateUrl: './key-editor.component.html',
  styleUrls: ['./key-editor.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class KeyEditorComponent implements OnInit {
  @Input() keyInstance?: UiKeyModel
  @Input() group!: UiGroupModel
  @Output() submitOutput = new EventEmitter<UiKeyModel>();


  ComydoKeyType = ComydoKeyType;
  ComydoKeyNames = ComydoKeyNames;

  keyFormGroup!: FormGroup
  keyRightFormGroup!: FormGroup
  sharingFormGroup!: FormGroup;

  currentCustomer!: CustomerModel;
  manageKeyHolderLink!: string;

  isNewKey?: boolean = false;
  minDate: Date = new Date();

  get keyRightsArray(): FormArray {
    let result = this.keyRightFormGroup.get("keyRights") as FormArray;
    return result;
  }

  get firstKeyRightGroup(): FormGroup {
    return this.keyRightsArray.at(0) as FormGroup;
  }

  get keyTypeControl(): AbstractControl {
    return this.keyFormGroup.get('generic')?.get('type')!;
  }

  get keyTeethControl(): AbstractControl {
    return this.keyFormGroup.get('keyTeeth')!;
  }

  keyHolders: Array<UiKeyHolderModel> = []
  //groups: Array<UiGroupModel> = []
  breadCrumpGroups: GroupModel[] | undefined = [];

  keyHolderMapper = new UiKeyHolderMapper();
  keyRightMapper = new UiKeyRightMapper();
  groupMapper = new UiGroupMapper();
  uiKeyMapper = new UiKeyMapper();

  unassignedKeys: Array<KeyModel> = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private getAllKeyHoldersUsecase: GetAllKeyHoldersUsecase,
    private getCustomerByGroupId: GetCustomerByGroupIdUsecase,
    public getAllUnassignedKeys: GetAllUnassignedKeysUsecase,
    private groupTreeService: GroupTreeService,
  ) { }

  ngOnInit() {
    this.loadKeyInstance();
    this.loadAllUnassignedKeys();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.keyInstance &&
      changes.keyInstance.currentValue != changes.keyInstance.previousValue) {
      this.loadKeyInstance();
    }
  }

  loadAllUnassignedKeys() {
    this.getCustomerByGroupId.execute(this.group.id!).subscribe(
      c => {
        this.currentCustomer = c;
        this.manageKeyHolderLink = '/home/customer/' + c.id + '/keyholder'
        this.getAllUnassignedKeys.execute(this.currentCustomer.id).subscribe(
          keys => {
            this.unassignedKeys = keys;
          }
        )
      }
    )
  }

  private loadKeyInstance() {
    if (this.keyInstance === undefined) {
      this.isNewKey = true;
      // template for new key
      this.keyInstance = {
        generic: {
          type: this.ComydoKeyType.Custom,
        },
        keyRights: [
          {
            startDate: moment(),
            startTime: "00:00",
            groupId: this.group.id!,
            isSingleUse: false,
            timeWindows: []
          }
        ],
      };
    }

    this.getAllKeyHoldersUsecase.execute().subscribe(this.onUpdateKeyHolders.bind(this));
    //this.getGroupsUseCase.execute().subscribe(this.onUpdateGroups.bind(this));
    this.breadCrumpGroups = this.groupTreeService.currentGroupBreadCrumpList$.getValue();

    this.keyFormGroup = this.fb.group({
      name: [this.keyInstance.name, Validators.required],
      generic: this.fb.group({
        type: [this.keyInstance.generic.type, Validators.required],
        licensePlate: [this.keyInstance!.generic.licensePlate],
      }),
      keyTeeth: [this.keyInstance!.keyTeeth],
      keyHolder: [this.keyInstance!.keyHolder],
    });

    if (this.isNewKey == false) {
      this.keyTypeControl.disable();
      this.keyTeethControl.disable();
    }

    this.setValidatorsAccordingToKeyType();
    this.fillKeyRightsForm();

  }

  setValidatorsAccordingToKeyType() {
    switch (this.keyFormGroup.get('generic')?.get('type')?.value) {
      case this.ComydoKeyType.Custom:
        this.clearValidators();
        break;
      case this.ComydoKeyType.PIN:
        this.clearValidators();
        this.keyFormGroup.get('keyTeeth')?.addValidators(
          [
            Validators.required,
            Validators.pattern('^[1-9]*')
          ]
        );
        break;
      case this.ComydoKeyType.Plate:
        this.clearValidators();
        this.keyFormGroup.get('generic')?.get('licensePlate')?.addValidators(
          [
            Validators.required,
            Validators.pattern('^[A-ZÄÖÜ]{1,3}[A-Z]{0,2}[0-9]{1,4}[EH]{0,1}')
          ]
        );
        break;
      case this.ComydoKeyType.Tracking:
        this.clearValidators();
        this.keyFormGroup.get('keyTeeth')?.addValidators(
          [Validators.required]
        );
        break;
      default:
        //throwError("Unknown Key Type Selected");
        break;
    }
    this.keyFormGroup.get('generic')?.get('licensePlate')?.updateValueAndValidity();
    this.keyFormGroup.get('keyTeeth')?.updateValueAndValidity();
    this.keyFormGroup.updateValueAndValidity();
  }

  private clearValidators() {
    this.keyFormGroup.get('generic')?.get('licensePlate')?.clearValidators();
    this.keyFormGroup.get('keyTeeth')?.clearValidators();
  }

  fillKeyRightsForm() {
    this.keyRightFormGroup = this.fb.group({
      keyRights: this.fb.array(
        this.keyInstance!.keyRights.map(this.buildKeyrightFormControl.bind(this))
      )
    });
  }

  buildKeyrightFormControl(keyRight: UiKeyRightModel): FormGroup {
    return this.fb.group({
      groupId: [keyRight.groupId, Validators.required],
      startDate: [keyRight.startDate],
      startTime: [keyRight.startTime],
      expirationDate: [keyRight.expirationDate],
      expirationTime: [keyRight.expirationTime],
      isSingleUse: [keyRight.isSingleUse, Validators.required],
      timeWindows: this.fb.array(
        keyRight.timeWindows.map(this.buildTimeWindowFormControl.bind(this))
      )
    });
  }

  buildTimeWindowFormControl(timeWindow: UiTimeWindowModel): FormGroup {
    return this.fb.group({
      startDate: [timeWindow.startDate],
      startTime: [timeWindow.startTime],
      endDate: [timeWindow.endDate],
      endTime: [timeWindow.endTime],
      reoccurrence: [timeWindow.reoccurrence, Validators.required]
    });
  }

  onCustomKeySelected() {
    this.setValidatorsAccordingToKeyType();
  }

  onLicensePlateKeySelected() {
    this.setValidatorsAccordingToKeyType();
  }

  onPinKeySelected() {
    this.setValidatorsAccordingToKeyType();
  }

  onTrackingKeySelected() {
    this.setValidatorsAccordingToKeyType();

    let expiryDate = moment();
    expiryDate.add(TRACKING_KEY_VALIDITY, 'days');

    this.keyInstance!.keyRights[0].isSingleUse = true;
    this.keyInstance!.keyRights[0].expirationDate = expiryDate;
    this.fillKeyRightsForm();
  }

  onUnassignedKeySelected(key: KeyModel) {
    this.keyTypeControl.disable();
    this.keyTeethControl.disable();
    this.isNewKey = false;
    this.keyInstance = this.uiKeyMapper.mapTo(key);
    this.keyFormGroup.get('type')?.setValue(key.generic.type);
    this.keyFormGroup.get('name')?.setValue(key.name);
    this.keyFormGroup.get('keyTeeth')?.setValue(key.keyTeeth);
    this.keyFormGroup.get('keyHolder')?.setValue(key.keyHolder);

  }

  onUpdateKeyHolders(keyHolders: Array<KeyHolderModel>) {
    this.keyHolders = keyHolders.map(this.keyHolderMapper.mapTo.bind(this.keyHolderMapper));
  }

  onUpdateGroups(groups: Array<GroupModel>) {
    this.breadCrumpGroups = this.groupTreeService.currentGroupBreadCrumpList$.getValue();
    //this.groups = groups.map(this.groupMapper.mapTo.bind(this.groupMapper));
  }

  getSelectedGroupForKeyRightFormGroup(formGroup: AbstractControl): UiGroupModel | undefined {
    try {
      let groupId = formGroup.value["groupId"]
      let candidates
      if (this.breadCrumpGroups) {
        candidates = this.breadCrumpGroups.filter(g => g.id == groupId)
      } else {
        return undefined;
      }

      if (candidates.length == 0) {
        return undefined;
      }
      return candidates[0]
    } catch {
      return undefined;
    }
  }

  getTimeWindwoControls(keyRightControl: AbstractControl): Array<AbstractControl> | undefined {
    let timeWindowFormArray = keyRightControl.get("timeWindows") as FormArray;
    return timeWindowFormArray.controls;
  }


  confirmKeyRightDelete(keyRightControl: AbstractControl) {
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "Key Right"
    });
    ref.afterClosed()
      .pipe(filter(result => result))
      .subscribe(this.deleteKeyRight.bind(this, keyRightControl))
  }

  private deleteKeyRight(keyRightControl: AbstractControl) {
    let index = this.keyRightsArray.controls.indexOf(keyRightControl);
    this.keyRightsArray.removeAt(index);
  }

  confirmTimeWindowDelete(keyRightIndex: number, timeWindowIndex: number) {
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "Time Restriction"
    });
    ref.afterClosed()
      .pipe(filter(result => result))
      .subscribe(this.deleteTimeWindow.bind(this, keyRightIndex, timeWindowIndex))
  }

  addTimeWindow(keyRightIndex: number) {
    let timeWindowControl = this.fb.group({
      startDate: [undefined, Validators.required],
      startTime: ["00:00", Validators.required],
      endDate: [undefined, Validators.required],
      endTime: ["00:00", Validators.required],
      reoccurrence: [ReoccurEnum.once, Validators.required]
    });
    let keyRight = this.keyRightsArray.controls[keyRightIndex];
    let timeWindowArray = keyRight.get("timeWindows") as FormArray;
    timeWindowArray.push(timeWindowControl);
  }

  addKeyRight() {
    let keyRight = this.fb.group({
      groupId: [this.group.id, Validators.required],
      startDate: [undefined, Validators.required],
      startTime: [undefined],
      expirationDate: [undefined],
      expirationTime: [undefined],
      isSingleUse: [false, Validators.required],
      timeWindows: this.fb.array([])
    });
    this.keyRightsArray.push(keyRight);
  }

  private deleteTimeWindow(keyRightIndex: number, timeWindowIndex: number) {
    let keyRight = this.keyRightsArray.controls[keyRightIndex];
    let timeWindowArray = keyRight.get("timeWindows") as FormArray;
    timeWindowArray.removeAt(timeWindowIndex);
  }

  //NOTE(nils): integration glue for @angular-material-components/datetime-picker
  // the datetime-toogle expects a MatDatepickerPanel in the "for" input. 
  // The datetime picker lib provides no such interface
  // public castPicker<T>(picker: NgxMatDatetimepicker<T>): DatetimePickerAdapter {
  //   return picker as unknown as DatetimePickerAdapter;
  // }

  onSubmit() {
    let result: UiKeyModel = this.keyInstance!;
    // if (!this.updateOriginal) {
    //   result = cloneDeep(this.keyInstance!); 
    // }

    // if this is not enabled, it will be stripped from the keyFormGroup 
    // this results in a deletion in the update case. not good.
    this.keyTypeControl.enable();
    Object.assign(result, this.keyFormGroup.value);
    Object.assign(result, this.keyRightFormGroup.value);

    this.submitOutput.emit(result);
  }

  private reset() {
    this.loadKeyInstance()
  }
}

//interface DatetimePickerAdapter extends MatDatepicker<any> {}


