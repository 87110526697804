import { inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';

export abstract class Language{
  code!: string;
  name!: string;
}
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private availableLanguages: Language[] = [{
      code: 'en',
      name: 'English',
    }, {
      code: 'de',
      name: 'German',
    }
  ];
  private availableLocales = ["DE", "GB", "FR", "IT",  "PT", "UK"];

  public currentLanguage$ = new BehaviorSubject(this.availableLanguages[0]);
  public currentLocale$ = new BehaviorSubject(this.availableLocales[0]);
  public defaultLanguage = 'de';
  public defaultLocale = 'DE';

  private readonly _adapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);
  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));

  constructor(private storage: LocalStorageService, private translateService: TranslateService) { }

  saveLanguage(langCode: string) {
    this.translateService.use(langCode);
    this.storage.set('lang', langCode);
    this.currentLanguage$.next(this.getLanguageFromCode(langCode))
  }

  saveLocale(locale: string){
    this.storage.set('locale', locale);
    this.setLocal(locale);
    this.currentLocale$.next(locale);
  }

  setLocal(locale: string){
    this._locale.set(locale.toLowerCase());
    this._adapter.setLocale(this._locale());
  }

  //returns stored language, otherwise first available language
  getLanguage(): Observable<Language> {
    let promise = this.storage.get('lang').then(storedLang => {
      if (!storedLang) {
        return this.getLanguageFromCode(this.defaultLanguage)
      }
      return this.getLanguageFromCode(storedLang)
    });
    return from(promise);
  }

  getLocale(): Observable<string> {
    let promise = this.storage.get('locale').then(storedLocale => {
      if (!storedLocale){
        return this.defaultLocale;
      }
      return storedLocale;
    });
    return from(promise);
  }

  getLanguageFromCode(code: string): Language{
    return this.availableLanguages.filter( (item) => item.code === code )[0]
  }

  getAvailableLanguages(){
    return this.availableLanguages
  }

  getBrowserLanguage(): string{
    return navigator.language
  }

  getAvailableLocales(){
    return this.availableLocales;
  }



}
